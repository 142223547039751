<template>
  <div class="global-list-main-container">
    <div>
      <div class="home-columns-container">
        <div class="column is-three-fifths home-products-container">
          <div class="point-of-sale-title">
            <p>
              Almacen:
              {{ warehouseInformation ? warehouseInformation.NOMBRE_ALM : "" }}
            </p>
          </div>
          <div class="point-of-sale-actions">
            <b-button type="is-primary" @click="openBarcodeReader()">
              Leer código de barras
            </b-button>

            <b-button type="is-primary" @click="openChangeCalculator()">
              Abrir calculadora de cambio
            </b-button>

            <b-button type="is-primary" @click="openAddSecret()">
              Usar secreto
            </b-button>

            <b-button
              type="is-primary"
              @click="reloadInformation()"
              :loading="refreshLoading"
            >
              Refrescar información
            </b-button>
          </div>
          <div class="point-of-sale-search">
            <b-input
              placeholder="Buscar artículo..."
              icon="magnify"
              icon-clickable
              v-model="searchInput"
              @input="searchArticles()"
              @keyup.enter="searchArticles()"
            >
            </b-input>
          </div>
          <div class="home-allproducts full-width-div">
            <!-- eslint-disable vue/no-use-v-if-with-v-for -->
            <div
              class="home-single-product"
              v-for="product of currentItems"
              :key="product._id"
              v-if="
                product.defaultListPrices &&
                product.defaultListPrices.length > 0
              "
              @click="addArticle(product)"
            >
              <div class="single-product-sku">
                {{ product.CLAVE_ART }}
              </div>
              <div class="single-product-name">
                {{ product.DESCRIPCION }}
              </div>
              <div class="single-product-price">
                {{ getFinalPriceWithTaxes(product) | money("MXN", 2) }} (Neto)
              </div>
              <div>
                Inventario:
                <strong>{{ getProductTotalQuantity(product) }}</strong>
              </div>
              <div>
                Inventario Ventas:
                <strong>{{ getProductTotalSalesQuantity(product) }}</strong>
              </div>
            </div>
          </div>
          <div class="full-width-div">
            <b-pagination
              :total="articlesTotal"
              :current.sync="current"
              range-before="2s"
              range-after="2"
              :per-page="perPage"
              order="is-centered"
              :rounded="true"
              aria-next-label="Next page"
              aria-previous-label="Previous page"
              aria-page-label="Page"
              aria-current-label="Current page"
              @change="
                (page) => {
                  this.current = page;
                  getArticlesNextPage();
                }
              "
            >
            </b-pagination>
          </div>
        </div>
        <div class="column home-sale-container">
          <div class="home-sale-user">
            <!--
            <div class="home-upper-control-buttons">
              <div
                class="home-upper-control-buttons-left"
                @click="openAddGlobalCost()"
              >
                Agregar indirecto
              </div>
              <div
                class="home-upper-control-buttons-right"
                @click="openAddGlobalDiscount()"
              >
                Agregar descuento
              </div>
            </div>
            -->
            <div class="home-sale-user-top full-width-div">
              <div class="full-width-div absolut-center-row special-margin">
                <b-checkbox
                  class="permissions-checkbox first-row-checkbox"
                  v-model="newSale.isAQuote"
                  v-if="
                    companyPaymentPlanModules &&
                    companyPaymentPlanModules.sales.saveQuotePos === 'S' &&
                    permissions &&
                    permissions.sales.saveQuotePos === 'S'
                  "
                >
                  Es una cotización
                </b-checkbox>
              </div>
              <div class="full-width-div absolut-center-row">
                <b-field
                  style="width: 72%"
                  label-position="inside"
                  label="Código aleatorio único (Opc)"
                  expanded
                >
                  <b-input
                    expanded
                    placeholder="Ejemplo: 123"
                    v-model="newSale.CODIGO_ENVIO"
                    icon="code-brackets"
                  >
                  </b-input>
                </b-field>
              </div>
            </div>
            <div class="home-sale-user-top full-width-div">
              <b-button
                icon-left="account"
                size="is-medium"
                class="home-select-user-button home-sale-button-top"
                type="is-white"
                @click="openSelectClient()"
              >
                {{
                  this.clientInformation
                    ? this.clientInformation.NOMBRE_EMPRESA
                    : "Seleccionar cliente"
                }}
              </b-button>
              <b-button
                icon-left="plus"
                size="is-medium"
                type="is-white"
                class="home-sale-button-top home-sale-icon-button"
                @click="openAddClient()"
              >
              </b-button>
            </div>
            <div class="home-sale-user-top full-width-div">
              <b-button
                icon-left="account-tie"
                size="is-medium"
                class="home-select-user-button home-sale-button-top"
                type="is-white"
                @click="openSelectSellerModal()"
                :disabled="
                  clientInformation && clientInformation.CLAVE_VENDEDOR
                "
              >
                {{
                  this.sellerInformation
                    ? `${this.sellerInformation.NOMBRE} ${this.sellerInformation.PATERNO} ${this.sellerInformation.MATERNO}`
                    : "Seleccionar vendedor"
                }}
              </b-button>
              <b-button
                icon-left="arrow-right"
                size="is-medium"
                type="is-white"
                class="home-sale-button-top home-sale-icon-button"
                @click="openSelectSellerModal()"
                :disabled="
                  clientInformation && clientInformation.CLAVE_VENDEDOR
                "
              >
              </b-button>
            </div>
            <div class="home-sale-single-product-header full-width-div">
              <div class="home-sale-single-product-left">Artículo</div>
              <div class="home-sale-single-product-medium">Detalles</div>
              <div class="home-sale-single-product-right">Precio</div>
            </div>
            <div class="home-sale-user-medium full-width-div">
              <div
                class="home-sale-single-product"
                v-for="product of newSale.currentItems"
                :key="product._id"
              >
                <div class="home-sale-single-product-left">
                  <div
                    class="home-sale-single-product-left-name home-sale-single-product-presentation"
                  >
                    {{ product.article.CLAVE_ART }}
                  </div>
                  <div class="home-sale-single-product-left-sku special-margin">
                    {{ product.article.DESCRIPCION }}
                  </div>
                  <div>
                    Inventario:
                    <strong>{{ product.article.totalQuantity }}</strong>
                  </div>
                </div>
                <div class="home-sale-single-product-medium">
                  <div class="home-sale-single-product-presentation">
                    <b-field class="invoice-option-select">
                      <b-select
                        placeholder="Ejemplo: PIEZA"
                        expanded
                        v-model="product.presentation"
                        @input="
                          () => {
                            updateTotals();
                          }
                        "
                      >
                        <option value="" disabled>
                          Seleccione una presentación
                        </option>
                        <option
                          value="UB"
                          v-if="!product.article.UNIDAD_EMP"
                          :disabled="
                            !checkArticleRepeatedPresentation(
                              product.article,
                              'UB'
                            )
                          "
                        >
                          Unidad de medida base
                        </option>
                        <option
                          value="UB"
                          v-if="product.article.UNIDAD_EMP"
                          :disabled="
                            !checkArticleRepeatedPresentation(
                              product.article,
                              'UB'
                            )
                          "
                        >
                          {{
                            capitalizeString(
                              product.article.UNIDAD_EMP.DESCRIP_MED
                            )
                          }}
                        </option>
                        <option
                          v-for="singlePresentation in product.article
                            .PRESENTACIONES"
                          :value="singlePresentation._id"
                          :key="singlePresentation._id"
                          :disabled="
                            !checkArticleRepeatedPresentation(
                              product.article,
                              singlePresentation._id
                            )
                          "
                        >
                          {{ singlePresentation.NOMBRE }}
                        </option>
                      </b-select>
                    </b-field>
                  </div>
                  <div class="home-sale-single-product-presentation">
                    <b-field class="invoice-option-select">
                      <b-select
                        placeholder="Ejemplo: PIEZA"
                        expanded
                        v-model="product.priceList"
                        @input="
                          () => {
                            updateTotals();
                          }
                        "
                      >
                        <option value="" disabled>
                          Seleccione una listas de precios
                        </option>
                        <option
                          v-for="singlePriceList in product.article
                            .defaultListPrices"
                          :value="singlePriceList.CLAVE_LISTAP"
                          :key="singlePriceList.CLAVE_LISTAP"
                        >
                          {{ singlePriceList.NOMBRE_LISTAPT }}
                        </option>
                      </b-select>
                    </b-field>
                  </div>
                  <div class="home-sale-single-product-quantity">
                    <div class="home-sale-single-product-less-quantity">
                      <b-button
                        type="is-primary"
                        @click="reduceQuantity(product)"
                      >
                        -
                      </b-button>
                    </div>

                    <div class="home-sale-single-product-quantity-input">
                      <b-input
                        type="number"
                        step="any"
                        v-model="product.quantity"
                        placeholder="cantidad"
                        min="0"
                        @input="
                          () => {
                            if (product.quantity && product.quantity < 0) {
                              removeRow(product);
                            }
                            updateTotals();
                          }
                        "
                      >
                      </b-input>
                    </div>

                    <div
                      class="home-sale-single-product-more-quantity"
                      @click="addQuantity(product)"
                    >
                      <b-button type="is-primary"> + </b-button>
                    </div>
                  </div>
                </div>
                <div class="home-sale-single-product-right">
                  <div>
                    <b-input
                      type="number"
                      step="any"
                      :value="getRowPrice(product)"
                      placeholder="Precio"
                      min="0"
                      @input="
                        (value) => {
                          updateRowPrice(value, product);
                        }
                      "
                    >
                    </b-input>
                  </div>
                </div>
              </div>
            </div>
            <div class="home-sale-user-bottom full-width-div">
              <div class="home-sale-user-bottom-sale-summary full-width-div">
                <div
                  class="home-sale-user-bottom-sale-summary-top full-width-div"
                >
                  <div>Subtotal</div>
                  <div>
                    {{ newSale.subtotal | money("MXN", 2) }}
                  </div>
                </div>
                <div
                  class="home-sale-user-bottom-sale-summary-bottom full-width-div"
                >
                  <div>Descuentos</div>
                  <div>
                    {{ newSale.discounts | money("MXN", 2) }}
                  </div>
                </div>
                <div
                  class="home-sale-user-bottom-sale-summary-bottom full-width-div"
                >
                  <div>Indirectos</div>
                  <div>{{ newSale.costs | money("MXN", 2) }}</div>
                </div>
                <div
                  class="home-sale-user-bottom-sale-summary-bottom full-width-div"
                >
                  <div>IVA</div>
                  <div>{{ newSale.iva | money("MXN", 2) }}</div>
                </div>
                <div
                  class="home-sale-user-bottom-sale-summary-bottom full-width-div"
                >
                  <div>IEPS</div>
                  <div>{{ newSale.ieps | money("MXN", 2) }}</div>
                </div>
              </div>
              <div
                class="home-sale-user-bottom-sale-buy"
                @click="
                  newSale.isAQuote ? saveQuotePos() : saveMissingSalePos()
                "
                v-if="posInformation.DOS_PASOS === 'S'"
              >
                <div class="home-sale-user-bottom-sale-buy-left full-width-div">
                  <div
                    class="home-sale-user-bottom-sale-buy-left-top full-width-div"
                  >
                    Agregar
                    {{
                      newSale.isAQuote
                        ? "cotización"
                        : "ticket pendiente de pago"
                    }}
                  </div>
                  <div
                    class="home-sale-user-bottom-sale-buy-left-bottom full-width-div"
                  >
                    {{ getTotalProducts() }} artículos
                  </div>
                </div>
                <div
                  class="home-sale-user-bottom-sale-buy-right full-width-div"
                >
                  {{ newSale.total | money("MXN", 2) }}
                </div>
              </div>

              <div
                class="home-sale-user-bottom-sale-buy"
                @click="
                  newSale.isAQuote ? saveQuotePos() : choosePaymentMethods()
                "
                v-if="
                  !posInformation.DOS_PASOS || posInformation.DOS_PASOS === 'N'
                "
              >
                <div class="home-sale-user-bottom-sale-buy-left full-width-div">
                  <div
                    class="home-sale-user-bottom-sale-buy-left-top full-width-div"
                  >
                    {{
                      newSale.isAQuote
                        ? "Agregar cotización"
                        : "  Ir al pago de la venta"
                    }}
                  </div>
                  <div
                    class="home-sale-user-bottom-sale-buy-left-bottom full-width-div"
                  >
                    {{ getTotalProducts() }} artículos
                  </div>
                </div>
                <div
                  class="home-sale-user-bottom-sale-buy-right full-width-div"
                >
                  {{ newSale.total | money("MXN", 2) }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AddClient from "../../Sales/components/AddClient";
import SelectClientModal from "../../Sales/components/SelectClientModal";
import SelectSellerModal from "../../HumanResources/components/SelectSellerModal";
import BarcodeReaderModal from "../../Sales/components/BarcodeReaderModal";
import AddGlobalCost from "../../Purchases/components/purchase-orders-actions/AddGlobalCost";
import AddGlobalDiscount from "../../Purchases/components/purchase-orders-actions/AddGlobalDiscount";
import AddPaymentMethodsPos from "../../Sales/components/AddPaymentMethodsPos.vue";
import PrintTicketModal from "../../Sales/components/PrintTicketModal.vue";
import CalculateChangeModal from "../../Sales/components/CalculateChangeModal";
import SearchSecretModal from "../../Sales/components/SearchSecretModal";
import {
  round,
  toFixedNoRound,
  capitalizeFirstLetter,
  createRandomStringCamps,
} from "@/utils/fns";
import { EventBus } from "@/event-bus";
import moment from "moment";
import debounce from "lodash/debounce";
// @ is an alias to /src
export default {
  name: "PointOfSale",
  components: {},
  data() {
    return {
      currentIndex: 1,
      newSale: {
        TIPO: "V",
        OBSERVA: "",
        FECHA: new Date(),
        ALMACEN: "",
        CLIENTE: "",
        total: 0,
        subtotal: 0,
        iva: 0,
        ieps: 0,
        COSTOS: [],
        DESCS: [],
        costs: 0,
        discounts: 0,
        printTimes: 0,
        CONDICION_P: "C",
        METODO_P: "P",
        currentItems: [],
        POS: "S",
        PERI_PAGO: 0,
        CLAVE_USO_SAT: "",
        CLAVE_REGIMEN: "",
        UUID_CFDI: "",
        TIPO_R: "",
        requireInvoice: false,
        requireInvoiceAfterSend: false,
        requireParcialInvoiceAfterSend: false,
        relateCFDI: false,
        VENDEDOR: undefined,
        DESBLOQUEO_CLIENTE: undefined,
        DESBLOQUEO_ALMACEN: undefined,
        sendMaterial: true,
        useAdvancedPayment: false,
        isAQuote: false,
        CODIGO_ENVIO: "",
        RAZON_SOCIAL: undefined,
      },
      clientInformation: "",
      sellerInformation: "",
      posInformation: "",
      warehouseInformation: "",
      searchInput: "",
      totalPages: 0,
      current: 1,
      perPage: 6,
      refreshLoading: false,
      blocSaleButton: false,
      secretUsed: false,
    };
  },
  async created() {
    moment.locale("es");

    this.$store.commit("SET_LOADING", true);

    this.calculatePages();
    this.checkIfCanUsePos();
    this.setPosInitialInformation();

    let allPromises = [];

    allPromises.push(
      this.$store.dispatch("GETARTICLESPAGINATED", {
        skip: this.perPage * (this.current - 1),
        limit: this.perPage,
        saleActive: true,
      })
    );

    await Promise.all(allPromises);

    this.$store.commit("SET_LOADING", false);
  },
  mounted() {
    EventBus.$on("clientSelectedSale", (client) => {
      this.newSale.CLIENTE = client.client._id;
      this.newSale.CLAVE_USO_SAT = client.client.CLAVE_USO_SAT;
      this.newSale.CLAVE_REGIMEN = client.client.CLAVE_REGIMEN;

      if (client.client.CLAVE_VENDEDOR) {
        this.newSale.VENDEDOR = client.client.CLAVE_VENDEDOR;

        for (const sinlgeEmploye of this.employees) {
          if (sinlgeEmploye._id.toString() === client.client.CLAVE_VENDEDOR) {
            this.sellerInformation = sinlgeEmploye;
          }
        }
      }

      this.clientInformation = client.client;

      this.setClientPrices(client.client);
    });

    EventBus.$on("sellerSelectedSale", (seller) => {
      this.newSale.VENDEDOR = seller.seller._id;
      this.newSale.NOMBRE_VENDEDOR = `${seller.seller.NOMBRE} ${seller.seller.PATERNO} ${seller.seller.MATERNO}`;
      this.sellerInformation = seller.seller;
    });

    EventBus.$on("barcodeRead", async (barCode) => {
      this.current = 1;
      this.$store.commit("SET_LOADING", true);
      await this.$store.dispatch("SEARCHARTICLES", {
        skip: this.perPage * (this.current - 1),
        limit: this.perPage,
        search: barCode.code,
        saleActive: true,
      });
      this.$store.commit("SET_LOADING", false);
    });

    EventBus.$on("saveGlobalDiscounts", (discount) => {
      this.newSale.DESCS = discount.discounts;
      this.updateTotals();
    });

    EventBus.$on("saveGlobalCost", (cost) => {
      this.newSale.COSTOS = cost.costs;
      this.updateTotals();
    });

    EventBus.$on("posSaleSaved", (savedSale) => {
      const saleInformation = { ...savedSale.saleInformation };
      saleInformation.CLAVE_VENTA =
        savedSale.responseInformation.updatedSale.CLAVE_VENTA;
      this.openPrintTicket(
        saleInformation,
        savedSale.responseInformation.updatedSale._id,
        savedSale.responseInformation.invoice,
        false
      );
      this.currentIndex = 1;
      this.secretUsed = false;
      this.newSale = {
        TIPO: "V",
        OBSERVA: "",
        FECHA: new Date(),
        ALMACEN: "",
        CLIENTE: "",
        total: 0,
        subtotal: 0,
        iva: 0,
        ieps: 0,
        COSTOS: [],
        DESCS: [],
        costs: 0,
        discounts: 0,
        printTimes: 0,
        CONDICION_P: "C",
        METODO_P: "P",
        currentItems: [],
        POS: "S",
        PERI_PAGO: 0,
        CLAVE_USO_SAT: "",
        CLAVE_REGIMEN: "",
        UUID_CFDI: "",
        TIPO_R: "",
        requireInvoice: false,
        requireInvoiceAfterSend: false,
        requireParcialInvoiceAfterSend: false,
        relateCFDI: false,
        VENDEDOR: undefined,
        DESBLOQUEO_CLIENTE: undefined,
        DESBLOQUEO_ALMACEN: undefined,
        sendMaterial: true,
        useAdvancedPayment: false,
        CLAVE_ANTICIPO: undefined,
        CANTIDAD_ANTICIPO: 0,
        RAZON_SOCIAL: undefined,
      };
      this.clientInformation = "";
      this.searchInput = "";
      this.setPosInitialInformation();
      this.setSellerInformation();
      if (this.companyInformation.AUTOCOMPLETAR_CODIGO_ALEATORIO) {
        this.newSale.CODIGO_ENVIO = this.getRandomString(3);
      }
    });

    EventBus.$on("secretFound", (secretInformation) => {
      // Change sale articles
      for (const [
        singleArticleIndex,
        singleArticle,
      ] of this.newSale.currentItems.entries()) {
        let found = false;
        this.secretUsed = true;

        // Move trough the default list prices to find if the one we want to add its already in the list
        for (const singlePriceList of singleArticle.article.defaultListPrices) {
          if (
            singlePriceList.CLAVE_LISTAP ===
            secretInformation.secret.LISTA_PRECIOS._id
          ) {
            found = true;
          }
        }

        // If price list was not found then push a new object to the default list prices array
        if (!found) {
          // Get final price
          let getFinalPriceResult = this.getFinalPrice(
            singleArticle.article,
            secretInformation.secret.LISTA_PRECIOS.PORC_UTILID
          );

          // Search for the price list in the article to see if it has another margin
          for (const singleArticlePriceList of singleArticle.article
            .LISTAS_PREC) {
            if (
              singleArticlePriceList.CLAVE_LISTAP ===
              secretInformation.secret.LISTA_PRECIOS._id
            ) {
              getFinalPriceResult = this.getFinalPrice(
                singleArticle.article,
                singleArticlePriceList.PORC_UTILID
              );
            }
          }

          this.newSale.currentItems[
            singleArticleIndex
          ].article.defaultListPrices.push({
            CLAVE_LISTAP: secretInformation.secret.LISTA_PRECIOS._id,
            DESCRIPCION: secretInformation.secret.LISTA_PRECIOS.DESCRIPCION,
            NOMBRE_LISTAPT: secretInformation.secret.LISTA_PRECIOS.CLAVE_LISTAP,
            PORC_UTILID: secretInformation.secret.LISTA_PRECIOS.PORC_UTILID,
            calculatedPrice: getFinalPriceResult.calculatedPrice,
            originalCalculatedPrice: getFinalPriceResult.calculatedPrice,
            calculatedUtility: getFinalPriceResult.calculatedUtility,
            forceRendering: 0,
            removable: true,
          });

          // Add new price list to presentations
          for (const [
            singlePresentationIndex,
            singlePresentation,
          ] of singleArticle.article.PRESENTACIONES.entries()) {
            // Get final price
            let finalPriceResult = this.getFinalPrice(
              singleArticle.article,
              secretInformation.secret.LISTA_PRECIOS.PORC_UTILID,
              false,
              0,
              false,
              singlePresentation
            );

            // Search for the price list in the article to see if it has another margin
            for (const singlePresentationPriceList of singlePresentation.LISTAS_PREC) {
              if (
                singlePresentationPriceList.CLAVE_LISTAP ===
                secretInformation.secret.LISTA_PRECIOS._id
              ) {
                finalPriceResult = this.getFinalPrice(
                  singleArticle.article,
                  singlePresentationPriceList.PORC_UTILID,
                  false,
                  0,
                  false,
                  singlePresentation
                );
              }
            }

            this.newSale.currentItems[
              singleArticleIndex
            ].article.PRESENTACIONES[
              singlePresentationIndex
            ].defaultListPrices.push({
              CLAVE_LISTAP: secretInformation.secret.LISTA_PRECIOS._id,
              DESCRIPCION: secretInformation.secret.LISTA_PRECIOS.DESCRIPCION,
              NOMBRE_LISTAPT:
                secretInformation.secret.LISTA_PRECIOS.CLAVE_LISTAP,
              PORC_UTILID: secretInformation.secret.LISTA_PRECIOS.PORC_UTILID,
              calculatedPrice: finalPriceResult.calculatedPrice,
              originalCalculatedPrice: finalPriceResult.calculatedPrice,
              calculatedUtility: finalPriceResult.calculatedUtility,
              forceRendering: 0,
              removable: true,
            });
          }
        }
      }

      // change all articles
      for (const [
        singleArticleIndex,
        singleArticle,
      ] of this.noEditedArticles.entries()) {
        let found = false;

        // Move trough the default list prices to find if the one we want to add its already in the list
        for (const singlePriceList of singleArticle.defaultListPrices) {
          if (
            singlePriceList.CLAVE_LISTAP ===
            secretInformation.secret.LISTA_PRECIOS._id
          ) {
            found = true;
          }
        }

        // If price list was not found then push a new object to the default list prices array
        if (!found) {
          // Get final price
          let getFinalPriceResult = this.getFinalPrice(
            singleArticle,
            secretInformation.secret.LISTA_PRECIOS.PORC_UTILID
          );

          // Search for the price list in the article to see if it has another margin
          for (const singleArticlePriceList of singleArticle.LISTAS_PREC) {
            if (
              singleArticlePriceList.CLAVE_LISTAP ===
              secretInformation.secret.LISTA_PRECIOS._id
            ) {
              getFinalPriceResult = this.getFinalPrice(
                singleArticle,
                singleArticlePriceList.PORC_UTILID
              );
            }
          }

          this.noEditedArticles[singleArticleIndex].defaultListPrices.push({
            CLAVE_LISTAP: secretInformation.secret.LISTA_PRECIOS._id,
            DESCRIPCION: secretInformation.secret.LISTA_PRECIOS.DESCRIPCION,
            NOMBRE_LISTAPT: secretInformation.secret.LISTA_PRECIOS.CLAVE_LISTAP,
            PORC_UTILID: secretInformation.secret.LISTA_PRECIOS.PORC_UTILID,
            calculatedPrice: getFinalPriceResult.calculatedPrice,
            originalCalculatedPrice: getFinalPriceResult.calculatedPrice,
            calculatedUtility: getFinalPriceResult.calculatedUtility,
            forceRendering: 0,
            removable: true,
          });

          // Add new price list to presentations
          for (const [
            singlePresentationIndex,
            singlePresentation,
          ] of singleArticle.PRESENTACIONES.entries()) {
            // Get final price
            let finalPriceResult = this.getFinalPrice(
              singleArticle,
              secretInformation.secret.LISTA_PRECIOS.PORC_UTILID,
              false,
              0,
              false,
              singlePresentation
            );

            // Search for the price list in the article to see if it has another margin
            for (const singlePresentationPriceList of singlePresentation.LISTAS_PREC) {
              if (
                singlePresentationPriceList.CLAVE_LISTAP ===
                secretInformation.secret.LISTA_PRECIOS._id
              ) {
                finalPriceResult = this.getFinalPrice(
                  singleArticle,
                  singlePresentationPriceList.PORC_UTILID,
                  false,
                  0,
                  false,
                  singlePresentation
                );
              }
            }

            this.noEditedArticles[singleArticleIndex].PRESENTACIONES[
              singlePresentationIndex
            ].defaultListPrices.push({
              CLAVE_LISTAP: secretInformation.secret.LISTA_PRECIOS._id,
              DESCRIPCION: secretInformation.secret.LISTA_PRECIOS.DESCRIPCION,
              NOMBRE_LISTAPT:
                secretInformation.secret.LISTA_PRECIOS.CLAVE_LISTAP,
              PORC_UTILID: secretInformation.secret.LISTA_PRECIOS.PORC_UTILID,
              calculatedPrice: finalPriceResult.calculatedPrice,
              originalCalculatedPrice: finalPriceResult.calculatedPrice,
              calculatedUtility: finalPriceResult.calculatedUtility,
              forceRendering: 0,
              removable: true,
            });
          }
        }
      }

      this.updateTotals();
    });

    for (const singleUser of this.users) {
      if (singleUser.email === this.userInformation.email) {
        for (const singleEmployee of this.employees) {
          if (singleUser._id === singleEmployee.USER) {
            this.newSale.VENDEDOR = singleEmployee._id;
            this.newSale.NOMBRE_VENDEDOR = `${singleEmployee.NOMBRE} ${singleEmployee.PATERNO} ${singleEmployee.MATERNO}`;
            this.sellerInformation = singleEmployee;
          }
        }
      }
    }

    if (this.companyInformation.AUTOCOMPLETAR_CODIGO_ALEATORIO) {
      this.newSale.CODIGO_ENVIO = this.getRandomString(3);
    }
  },
  beforeDestroy() {
    EventBus.$off();
  },
  methods: {
    async reloadInformation() {
      this.refreshLoading = true;

      moment.locale("es");

      this.current = 1;

      let allPromises = [];

      allPromises.push(
        this.$store.dispatch("GETPOSNOSALES", {
          warehouses: [],
        })
      );
      allPromises.push(this.$store.dispatch("GETCLIENTS"));

      await Promise.all(allPromises);

      this.calculatePages();
      this.setPosInitialInformation();
      this.setSellerInformation();

      if (this.clientInformation) {
        this.setClientPrices(this.clientInformation, false);
      }

      this.refreshLoading = false;
    },
    setClientPrices(client, resetArticles = true) {
      const formattedClientPricesLists = [...client.LISTAS_PRECIOS];

      // push default price list of client lists don't have it already
      let defaultPriceListFound = false;
      for (const singleClientPriceList of formattedClientPricesLists) {
        if (
          client.LISTA_PRECIOS_PRE &&
          singleClientPriceList.CLAVE_LISTAP ===
            client.LISTA_PRECIOS_PRE.CLAVE_LISTAP
        ) {
          defaultPriceListFound = true;
        }
      }
      if (!defaultPriceListFound) {
        formattedClientPricesLists.push(client.LISTA_PRECIOS_PRE);
      }

      for (const [
        singleArticleIndex,
        singleArticle,
      ] of this.newSale.currentItems.entries()) {
        if (resetArticles) {
          // Set selected price list to the first in default ptices list which is the default in the point of sale
          this.newSale.currentItems[singleArticleIndex].priceList =
            this.newSale.currentItems[
              singleArticleIndex
            ].article.defaultListPrices[0].CLAVE_LISTAP;
        }

        // Reset article prices lists
        let nonDeletedPricesLists = [];
        for (const singlePriceList of singleArticle.article.defaultListPrices) {
          if (!singlePriceList.removable) {
            nonDeletedPricesLists.push(singlePriceList);
          }
        }
        this.newSale.currentItems[
          singleArticleIndex
        ].article.defaultListPrices = nonDeletedPricesLists;

        // Add new price list to presentations
        for (const [
          singlePresentationIndex,
          singlePresentation,
        ] of singleArticle.article.PRESENTACIONES.entries()) {
          // Reset presentations prices lists
          let nonDeletedPresentationsPricesLists = [];
          for (const singlePriceList of singlePresentation.defaultListPrices) {
            if (!singlePriceList.removable) {
              nonDeletedPresentationsPricesLists.push(singlePriceList);
            }
          }
          this.newSale.currentItems[singleArticleIndex].article.PRESENTACIONES[
            singlePresentationIndex
          ].defaultListPrices = nonDeletedPresentationsPricesLists;
        }

        for (const singleClientList of formattedClientPricesLists) {
          let found = false;

          // Move through the default list prices to find if the one we want to add its already in the list
          for (const [
            singlePriceListIndex,
            singlePriceList,
          ] of this.newSale.currentItems[
            singleArticleIndex
          ].article.defaultListPrices.entries()) {
            if (
              singleClientList &&
              singlePriceList.CLAVE_LISTAP === singleClientList._id
            ) {
              found = true;
            }
          }

          // If price list was not found then push a new object to the default list prices array
          if (!found && singleClientList) {
            let finalPriceResult = this.getFinalPrice(
              singleArticle.article,
              singleClientList.PORC_UTILID
            );
            let marginPercentage = singleClientList.PORC_UTILID;

            // Search for the price list in the article to see if it has another margin
            for (const singleArticlePriceList of singleArticle.article
              .LISTAS_PREC) {
              if (
                singleArticlePriceList.CLAVE_LISTAP === singleClientList._id
              ) {
                finalPriceResult = this.getFinalPrice(
                  singleArticle.article,
                  singleArticlePriceList.PORC_UTILID
                );
                marginPercentage = singleArticlePriceList.PORC_UTILID;
              }
            }

            this.newSale.currentItems[
              singleArticleIndex
            ].article.defaultListPrices.push({
              CLAVE_LISTAP: singleClientList._id,
              DESCRIPCION: singleClientList.DESCRIPCION,
              NOMBRE_LISTAPT: singleClientList.CLAVE_LISTAP,
              PORC_UTILID: marginPercentage,
              calculatedPrice: finalPriceResult.calculatedPrice,
              originalCalculatedPrice: finalPriceResult.calculatedPrice,
              calculatedUtility: finalPriceResult.calculatedUtility,
              forceRendering: 0,
              removable: true,
            });

            // Add new price list to presentations
            for (const [
              singlePresentationIndex,
              singlePresentation,
            ] of singleArticle.article.PRESENTACIONES.entries()) {
              let finalPriceResult = this.getFinalPrice(
                singleArticle.article,
                singleClientList.PORC_UTILID,
                false,
                0,
                false,
                singlePresentation
              );

              // Search for the price list in the article to see if it has another margin
              for (const singlePresentationPriceList of singlePresentation.LISTAS_PREC) {
                if (
                  singlePresentationPriceList.CLAVE_LISTAP ===
                  singleClientList._id
                ) {
                  finalPriceResult = this.getFinalPrice(
                    singleArticle.article,
                    singlePresentationPriceList.PORC_UTILID,
                    false,
                    0,
                    false,
                    singlePresentation
                  );
                }
              }

              this.newSale.currentItems[
                singleArticleIndex
              ].article.PRESENTACIONES[
                singlePresentationIndex
              ].defaultListPrices.push({
                CLAVE_LISTAP: singleClientList._id,
                DESCRIPCION: singleClientList.DESCRIPCION,
                NOMBRE_LISTAPT: singleClientList.CLAVE_LISTAP,
                PORC_UTILID: singleClientList.PORC_UTILID,
                calculatedPrice: finalPriceResult.calculatedPrice,
                originalCalculatedPrice: finalPriceResult.calculatedPrice,
                calculatedUtility: finalPriceResult.calculatedUtility,
                forceRendering: 0,
                removable: true,
              });
            }
          }
        }
      }

      for (const [
        singleArticleIndex,
        singleArticle,
      ] of this.noEditedArticles.entries()) {
        // Reset article prices lists
        let nonDeletedPricesLists = [];
        for (const singlePriceList of this.noEditedArticles[singleArticleIndex]
          .defaultListPrices) {
          if (!singlePriceList.removable) {
            nonDeletedPricesLists.push(singlePriceList);
          }
        }
        this.noEditedArticles[singleArticleIndex].defaultListPrices =
          nonDeletedPricesLists;

        // Add new price list to presentations
        for (const [
          singlePresentationIndex,
          singlePresentation,
        ] of singleArticle.PRESENTACIONES.entries()) {
          // Reset presentations prices lists
          let nonDeletedPresentationsPricesLists = [];
          for (const singlePriceList of singlePresentation.defaultListPrices) {
            if (!singlePriceList.removable) {
              nonDeletedPresentationsPricesLists.push(singlePriceList);
            }
          }
          this.noEditedArticles[singleArticleIndex].PRESENTACIONES[
            singlePresentationIndex
          ].defaultListPrices = nonDeletedPresentationsPricesLists;
        }

        for (const singleClientList of formattedClientPricesLists) {
          if (singleClientList) {
            let found = false;

            // Move through the default list prices to find if the one we want to add its already in the list
            for (const [
              singlePriceListIndex,
              singlePriceList,
            ] of this.noEditedArticles[
              singleArticleIndex
            ].defaultListPrices.entries()) {
              if (singlePriceList.CLAVE_LISTAP === singleClientList._id) {
                found = true;
              }
            }

            // If price list was not found then push a new object to the default list prices array
            if (!found) {
              let finalPriceResult = this.getFinalPrice(
                singleArticle,
                singleClientList.PORC_UTILID
              );
              let marginPercentage = singleClientList.PORC_UTILID;

              // Search for the price list in the article to see if it has another margin
              for (const singleArticlePriceList of singleArticle.LISTAS_PREC) {
                if (
                  singleArticlePriceList.CLAVE_LISTAP === singleClientList._id
                ) {
                  finalPriceResult = this.getFinalPrice(
                    singleArticle,
                    singleArticlePriceList.PORC_UTILID
                  );

                  marginPercentage = singleArticlePriceList.PORC_UTILID;
                }
              }

              this.noEditedArticles[singleArticleIndex].defaultListPrices.push({
                CLAVE_LISTAP: singleClientList._id,
                DESCRIPCION: singleClientList.DESCRIPCION,
                NOMBRE_LISTAPT: singleClientList.CLAVE_LISTAP,
                PORC_UTILID: marginPercentage,
                calculatedPrice: finalPriceResult.calculatedPrice,
                originalCalculatedPrice: finalPriceResult.calculatedPrice,
                calculatedUtility: finalPriceResult.calculatedUtility,
                forceRendering: 0,
                removable: true,
              });

              // Add new price list to presentations
              for (const [
                singlePresentationIndex,
                singlePresentation,
              ] of singleArticle.PRESENTACIONES.entries()) {
                let finalPriceResult = this.getFinalPrice(
                  singleArticle,
                  singleClientList.PORC_UTILID,
                  false,
                  0,
                  false,
                  singlePresentation
                );
                let marginPercentage = singleClientList.PORC_UTILID;

                // Search for the price list in the article to see if it has another margin
                for (const singlePresentationPriceList of singlePresentation.LISTAS_PREC) {
                  if (
                    singlePresentationPriceList.CLAVE_LISTAP ===
                    singleClientList._id
                  ) {
                    finalPriceResult = this.getFinalPrice(
                      singleArticle,
                      singlePresentationPriceList.PORC_UTILID,
                      false,
                      0,
                      false,
                      singlePresentation
                    );
                    marginPercentage = singlePresentationPriceList.PORC_UTILID;
                  }
                }

                this.noEditedArticles[singleArticleIndex].PRESENTACIONES[
                  singlePresentationIndex
                ].defaultListPrices.push({
                  CLAVE_LISTAP: singleClientList._id,
                  DESCRIPCION: singleClientList.DESCRIPCION,
                  NOMBRE_LISTAPT: singleClientList.CLAVE_LISTAP,
                  PORC_UTILID: marginPercentage,
                  calculatedPrice: finalPriceResult.calculatedPrice,
                  originalCalculatedPrice: finalPriceResult.calculatedPrice,
                  calculatedUtility: finalPriceResult.calculatedUtility,
                  forceRendering: 0,
                  removable: true,
                });
              }
            }
          }
        }
      }

      this.updateTotals();
    },
    capitalizeString(string) {
      return capitalizeFirstLetter(string);
    },
    calculatePages() {
      const currentTotalPages = Math.ceil(this.articles.length / this.perPage);
      this.totalPages = currentTotalPages;
    },
    /**
     *
     * @desc checks user to see if all pos needed info is set and caja is open
     */
    checkIfCanUsePos() {
      if (!this.userInformation.pointOfSale) {
        this.closeUserPos();
        return;
      }

      for (const singlePos of this.pos) {
        if (singlePos._id === this.userInformation.pointOfSale._id) {
          if (singlePos.STATUS !== "AB") {
            this.closeUserPos();
          }
        }
      }
    },
    /**
     *
     * @desc closes the user pos if the needed info is not set
     */
    closeUserPos() {
      this.$buefy.dialog.confirm({
        title: "Punto de venta no disponible",
        message:
          "Este usuario no cuenta con una caja seleccionada o la caja no se encuentra abierta, compruebe con el administrador de su compañía.",
        confirmText: "Entendido",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => {
          this.$router.push("/Dashboards/Initial");
        },
        cancelText: "cancelar",
        canCancel: false,
      });
    },
    /**
     *
     * @desc sets the pos needed information
     */
    setPosInitialInformation() {
      for (const singlePos of this.pos) {
        if (singlePos._id === this.userInformation.pointOfSale._id) {
          this.warehouseInformation = singlePos.ALMACEN;
          this.newSale.ALMACEN = singlePos.ALMACEN._id;
          this.posInformation = singlePos;
        }
      }
    },
    /**
     *
     * @desc opens add new client modal
     */
    openAddClient() {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: AddClient,
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    /**
     *
     * @desc opens the select client modal
     */
    openSelectClient() {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: SelectClientModal,
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    /**
     *
     * @desc opens the select seller modal
     */
    openSelectSellerModal() {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: SelectSellerModal,
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    /**
     *
     * @desc open the barcode reader
     */
    openBarcodeReader() {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: BarcodeReaderModal,
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    /**
     *
     * @param currentArticle current article to get the minimum sale cost
     * @param utility the utility to use
     * @param change if set to true this will change the article object
     * @param index the index of the price list
     * @param onlyMainArticle if its the main article or a presentation
     * @param presentation the presentation info
     * @returns {string} the final price calculation
     */
    getFinalPrice(
      currentArticle,
      utility,
      change = false,
      index,
      onlyMainArticle = true,
      presentation = undefined
    ) {
      let finalMinimumCost = 0;
      let finalUtility = 0;
      if (currentArticle.COSTO_MIN_VTA) {
        if (onlyMainArticle) {
          // Add the utility margin to the calculation for article
          let { COSTO_MIN_VTA } = { ...currentArticle };
          finalMinimumCost =
            Number(COSTO_MIN_VTA) + COSTO_MIN_VTA * (utility / 100);
          finalUtility = Number(COSTO_MIN_VTA) * (utility / 100);
          // Add the Merma to the calculation
          if (currentArticle.PORC_MERMA)
            finalMinimumCost +=
              COSTO_MIN_VTA * (currentArticle.PORC_MERMA / 100);
          // Add other cost to the calculation
          if (currentArticle.PORC_INDIREC)
            finalMinimumCost +=
              COSTO_MIN_VTA * (currentArticle.PORC_INDIREC / 100);
          // Add shipping cost to the calculation
          if (currentArticle.PORC_FLETE)
            finalMinimumCost +=
              COSTO_MIN_VTA * (currentArticle.PORC_FLETE / 100);
        } else {
          // Add the utility margin to the calculation for presentation
          let { COSTO_MIN_VTA } = { ...currentArticle };
          let { COSTO_MIN_VTA_PRESENT } = { ...currentArticle };
          // Add the Merma to the calculation
          if (currentArticle.PORC_MERMA)
            finalMinimumCost +=
              COSTO_MIN_VTA * (currentArticle.PORC_MERMA / 100);
          // Add other cost to the calculation
          if (currentArticle.PORC_INDIREC)
            finalMinimumCost +=
              COSTO_MIN_VTA * (currentArticle.PORC_INDIREC / 100);
          // Add shipping cost to the calculation
          if (currentArticle.PORC_FLETE)
            finalMinimumCost +=
              COSTO_MIN_VTA * (currentArticle.PORC_FLETE / 100);
          if (presentation.ESMAYOR === "S") {
            COSTO_MIN_VTA_PRESENT = COSTO_MIN_VTA * presentation.FACTOR2;
          } else {
            COSTO_MIN_VTA_PRESENT = COSTO_MIN_VTA / presentation.FACTOR2;
          }
          finalMinimumCost +=
            Number(COSTO_MIN_VTA_PRESENT) +
            COSTO_MIN_VTA_PRESENT * (utility / 100);
          finalUtility = Number(COSTO_MIN_VTA_PRESENT) * (utility / 100);
        }
      }
      if (change) {
        if (!onlyMainArticle) {
          for (const [
            singlePresentationIndex,
            singlePresentation,
          ] of currentArticle.PRESENTACIONES.entries()) {
            if (singlePresentation._id === presentation._id) {
              currentArticle.PRESENTACIONES[
                singlePresentationIndex
              ].defaultListPrices[index].calculatedPrice =
                parseFloat(finalMinimumCost).toFixed(5);
              currentArticle.PRESENTACIONES[
                singlePresentationIndex
              ].defaultListPrices[index].originalCalculatedPrice =
                parseFloat(finalMinimumCost).toFixed(5);
              currentArticle.PRESENTACIONES[
                singlePresentationIndex
              ].defaultListPrices[index].calculatedUtility =
                parseFloat(finalUtility).toFixed(5);
            }
          }
          // Force rendering changing an atribute on the articles list, in this case we change forceRendering property
          currentArticle.defaultListPrices[index].forceRendering += 1;
        } else {
          currentArticle.defaultListPrices[index].calculatedPrice =
            parseFloat(finalMinimumCost).toFixed(5);
          currentArticle.defaultListPrices[index].originalCalculatedPrice =
            parseFloat(finalMinimumCost).toFixed(5);
          currentArticle.defaultListPrices[index].calculatedUtility =
            parseFloat(finalUtility).toFixed(5);
        }
      }
      return {
        calculatedPrice: parseFloat(finalMinimumCost.toString()).toFixed(5),
        calculatedUtility: parseFloat(finalUtility.toString()).toFixed(5),
      };
    },
    getFinalPriceWithTaxes(article) {
      let initialArticlePrice = article.defaultListPrices[0].calculatedPrice;
      let articleIvaTotal = 0;
      let artivleIepsTotal = 0;

      // Calculate iva
      if (article.CANT_IVA !== undefined && article.CANT_IVA !== null) {
        articleIvaTotal += initialArticlePrice * (article.CANT_IVA / 100);
      } else {
        articleIvaTotal += initialArticlePrice * (16 / 100);
      }

      // Calculate ieps
      if (article.CANT_IEPS !== undefined && article.CANT_IEPS !== null) {
        artivleIepsTotal += initialArticlePrice * (article.CANT_IEPS / 100);
      }

      return round(
        Number(initialArticlePrice) +
          Number(articleIvaTotal) +
          Number(artivleIepsTotal),
        2
      );
    },
    addArticle(article) {
      let foundItem = false;
      for (const singleItem of this.newSale.currentItems) {
        if (
          singleItem.article._id === article._id &&
          singleItem.presentation === "UB"
        ) {
          foundItem = true;
          singleItem.quantity++;
          break;
        }
      }

      if (!foundItem) {
        this.newSale.currentItems.push({
          article: article,
          presentation: "UB",
          quantity: 1,
          RENGLON: this.currentIndex,
          subTotal: 0,
          price: 0,
          finalPrice: 0,
          costTotal: 0,
          discountTotal: 0,
          costs: [],
          discounts: [],
          priceList: this.posInformation.LISTA_PRECIOS[0]._id,
        });
        this.currentIndex++;
        this.updateTotals();
      }
    },
    addQuantity(row) {
      row.quantity++;
      this.updateTotals();
    },
    reduceQuantity(row) {
      row.quantity--;
      if (row.quantity <= 0) {
        this.removeRow(row);
      }
      this.updateTotals();
    },
    removeRow(row) {
      for (const [
        singleItemIndex,
        singleItem,
      ] of this.newSale.currentItems.entries()) {
        if (singleItem.RENGLON === row.RENGLON) {
          this.newSale.currentItems.splice(singleItemIndex, 1);
        }
      }
      this.currentIndex--;
    },
    /**
     *
     * @desc gets the IVA from an article
     */
    getIva(item) {
      if (item.article) {
        if (
          item.article.CANT_IVA !== undefined &&
          item.article.CANT_IVA !== null
        ) {
          return item.article.CANT_IVA;
        } else {
          return 16;
        }
      }
      return 0;
    },
    /**
     *
     * @desc gets the IPES from an article
     */
    getIeps(item) {
      if (item.article) {
        if (
          item.article.CANT_IEPS !== undefined &&
          item.article.CANT_IEPS !== null
        ) {
          return item.article.CANT_IEPS;
        } else {
          return 0;
        }
      }
      return 0;
    },
    /**
     *
     * @desc updates totals of the new sale and calculates taxes from all products
     */
    updateTotals() {
      let total = 0;
      let subtotal = 0;
      let iva = 0;
      let ieps = 0;
      let cost = 0;
      let costsTotal = 0;
      let discount = 0;
      let discountsTotal = 0;
      let excent = 0;
      let utilityTotal = 0;

      for (const [
        singleItemIndex,
        singleItem,
      ] of this.newSale.currentItems.entries()) {
        if (singleItem.article) {
          let CANTIDAD_PRESENTACION = 1;

          let costItem = 0;
          let discountItem = 0;
          let priceQuantity = 0;

          let selectedPriceListIndex = 0;

          // Get index of selected price list
          for (const [
            singlePriceListIndex,
            singlePriceList,
          ] of singleItem.article.defaultListPrices.entries()) {
            if (singlePriceList.CLAVE_LISTAP === singleItem.priceList) {
              selectedPriceListIndex = singlePriceListIndex;
            }
          }

          // Set exento if the item is exento
          if (singleItem.article.TIPO_IVA === "EX") {
            this.newSale.currentItems[singleItemIndex].EXENTO = "S";
          }

          if (singleItem.presentation === "UB") {
            let price = round(
              singleItem.article.defaultListPrices[selectedPriceListIndex]
                .calculatedPrice,
              2
            );
            singleItem.quantity;
            priceQuantity = Number(price * singleItem.quantity);
            this.newSale.currentItems[singleItemIndex].price = price;
          } else if (singleItem.presentation !== "UB") {
            for (const singlePresentation of singleItem.article
              .PRESENTACIONES) {
              if (singlePresentation._id === singleItem.presentation) {
                let price = round(
                  singlePresentation.defaultListPrices[selectedPriceListIndex]
                    .calculatedPrice,
                  2
                );
                singleItem.quantity;
                priceQuantity = Number(price * singleItem.quantity);
                this.newSale.currentItems[singleItemIndex].price = price;

                // Set presentation quantity
                if (singlePresentation.ESMAYOR === "S") {
                  CANTIDAD_PRESENTACION =
                    1 * Number(singlePresentation.FACTOR2);
                } else {
                  CANTIDAD_PRESENTACION =
                    1 / Number(singlePresentation.FACTOR2);
                }
              }
            }
          }

          for (const cost of singleItem.costs) {
            costItem += cost.CANTIDAD;
          }

          costsTotal += singleItem.quantity * costItem;

          for (const discount of singleItem.discounts) {
            discountItem += discount.CANTIDAD;
          }

          discountsTotal += singleItem.quantity * discountItem;

          let subTotalItem =
            priceQuantity +
            singleItem.quantity * costItem -
            singleItem.quantity * discountItem;

          subtotal += subTotalItem;

          let totalItem = 0;
          totalItem += subTotalItem;

          // Add Iva to the total
          let itemIva = subTotalItem * (this.getIva(singleItem) / 100);
          totalItem += itemIva;
          iva += itemIva;

          // Add Ieps to the total
          let itemIeps = subTotalItem * (this.getIeps(singleItem) / 100);
          totalItem += itemIeps;
          ieps += itemIeps;

          // Add iva excent to excent total
          if (singleItem.article.TIPO_IVA === "EX") {
            excent += priceQuantity;
          }

          this.newSale.currentItems[singleItemIndex].CANTIDAD_PRESENTACION =
            CANTIDAD_PRESENTACION;

          // Set single item COSTO_MIN_VTA to the current COSTO_MIN_VTA of the article
          this.newSale.currentItems[singleItemIndex].COSTO_MIN_VTA =
            singleItem.article.COSTO_MIN_VTA * singleItem.CANTIDAD_PRESENTACION;

          this.newSale.currentItems[singleItemIndex].subTotal = toFixedNoRound(
            subTotalItem,
            3
          );
          this.newSale.currentItems[singleItemIndex].discountTotal =
            toFixedNoRound(discountItem, 3);
          this.newSale.currentItems[singleItemIndex].costTotal = toFixedNoRound(
            costItem,
            3
          );
          this.newSale.currentItems[singleItemIndex].finalPrice =
            toFixedNoRound(totalItem, 3);
          this.newSale.currentItems[singleItemIndex].IVA_GTO = toFixedNoRound(
            itemIva,
            3
          );
          this.newSale.currentItems[singleItemIndex].IEPS_GTO = toFixedNoRound(
            itemIeps,
            3
          );
          this.newSale.currentItems[singleItemIndex].utility = toFixedNoRound(
            Number(singleItem.price) +
              costItem -
              discountItem -
              Number(
                singleItem.article.COSTO_MIN_VTA *
                  singleItem.CANTIDAD_PRESENTACION
              ),
            3
          );
          this.newSale.currentItems[singleItemIndex].utilityTotal =
            toFixedNoRound(
              this.newSale.currentItems[singleItemIndex].utility *
                singleItem.quantity,
              3
            );
          utilityTotal +=
            this.newSale.currentItems[singleItemIndex].utilityTotal;
        }
      }

      for (let singleCost of this.newSale.COSTOS) {
        if (singleCost.TIPO === "PO") {
          let costQuantity = subtotal * (singleCost.PORCENTAJE / 100);
          cost += costQuantity;
          costsTotal += costQuantity;
          iva += costQuantity * 0.16;
        } else if (singleCost.TIPO === "CA") {
          cost += Number(singleCost.CANTIDAD);
          costsTotal += Number(singleCost.CANTIDAD);
          iva += Number(singleCost.CANTIDAD * 0.16);
        }
      }

      for (let singleDiscount of this.newSale.DESCS) {
        if (singleDiscount.TIPO === "PO") {
          let discountQuantity = subtotal * (singleDiscount.PORCENTAJE / 100);
          discount += discountQuantity;
          discountsTotal += discountQuantity;
          iva -= discountQuantity * 0.16;
        } else if (singleDiscount.TIPO === "CA") {
          discount += Number(singleDiscount.CANTIDAD);
          discountsTotal += Number(singleDiscount.CANTIDAD);
          iva -= Number(singleDiscount.CANTIDAD * 0.16);
        }
      }

      total += subtotal - discount + cost + iva + ieps;

      this.newSale.subtotal = toFixedNoRound(subtotal, 3);
      this.newSale.total = toFixedNoRound(total, 3);
      this.newSale.discounts = toFixedNoRound(discountsTotal, 3);
      this.newSale.costs = toFixedNoRound(costsTotal, 3);
      this.newSale.iva = toFixedNoRound(iva, 3);
      this.newSale.ieps = toFixedNoRound(ieps, 3);
      this.newSale.EXENTO = toFixedNoRound(excent, 3);
      this.newSale.UTILIDAD = toFixedNoRound(utilityTotal, 3);
    },
    /**
     *
     * @desc gets the total amount of quantity from products
     */
    getTotalProducts() {
      let totalItems = 0;
      for (const singleItem of this.newSale.currentItems) {
        totalItems += Number(singleItem.quantity);
      }
      return totalItems;
    },
    checkArticleRepeatedPresentation(article, presentation) {
      // Substract presentations already used
      for (const singleItem of this.newSale.currentItems) {
        if (
          singleItem.article &&
          singleItem.article._id === article._id &&
          singleItem.presentation === presentation
        ) {
          return false;
        }
      }
      return true;
    },
    /**
     *
     * @desc get the price for one single article or presentation
     */
    getRowPrice(row) {
      if (row.presentation === "UB") {
        for (const [
          singleArticlePriceListIndex,
          singleArticlePriceList,
        ] of row.article.defaultListPrices.entries()) {
          if (singleArticlePriceList.CLAVE_LISTAP === row.priceList) {
            return Number(
              row.article.defaultListPrices[singleArticlePriceListIndex]
                .calculatedPrice
            );
          }
        }
      } else if (row.presentation !== "UB") {
        for (const singlePresentation of row.article.PRESENTACIONES) {
          if (singlePresentation._id === row.presentation) {
            for (const [
              singlePresentationPriceListIndex,
              singlePresentationPriceList,
            ] of singlePresentation.defaultListPrices.entries()) {
              if (singlePresentationPriceList.CLAVE_LISTAP === row.priceList) {
                return Number(
                  singlePresentation.defaultListPrices[
                    singlePresentationPriceListIndex
                  ].calculatedPrice
                );
              }
            }
          }
        }
      }
      return 0;
    },
    updateRowPrice(value, row) {
      if (row.presentation === "UB") {
        for (const [
          singlePriceListIndex,
          singlePriceList,
        ] of row.article.defaultListPrices.entries()) {
          if (
            singlePriceList.CLAVE_LISTAP.toString() === row.priceList.toString()
          ) {
            row.article.defaultListPrices[
              singlePriceListIndex
            ].calculatedPrice = value;
          }
        }
      } else if (row.presentation !== "UB") {
        for (const [
          singlePresentationIndex,
          singlePresentation,
        ] of row.article.PRESENTACIONES.entries()) {
          if (singlePresentation._id === row.presentation) {
            for (const [
              singlePriceListIndex,
              singlePriceList,
            ] of singlePresentation.defaultListPrices.entries()) {
              if (
                singlePriceList.CLAVE_LISTAP.toString() ===
                row.priceList.toString()
              ) {
                row.article.PRESENTACIONES[
                  singlePresentationIndex
                ].defaultListPrices[singlePriceListIndex].calculatedPrice =
                  value;
              }
            }
          }
        }
      }
      this.updateTotals();
    },
    openAddGlobalCost() {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: AddGlobalCost,
        props: {
          allCosts: this.newSale.COSTOS,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    openAddGlobalDiscount() {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: AddGlobalDiscount,
        props: {
          allDiscounts: this.newSale.DESCS,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    /**
     *
     * @desc opens the page to select a payment method
     */
    choosePaymentMethods() {
      if (!this.newSale.VENDEDOR) {
        this.$buefy.dialog.confirm({
          title: "No hay vendedor seleccionado",
          message: `No se tiene un vendedor seleccionado ¿Esta seguro que desea continua?`,
          type: "is-danger",
          hasIcon: true,
          cancelText: "Cancelar",
          confirmText: "Confirmar",
          onConfirm: async () => {
            this.choosePaymentMethodsLogic();
          },
        });
      } else {
        this.choosePaymentMethodsLogic();
      }
    },
    choosePaymentMethodsLogic() {
      if (this.blocSaleButton) {
        return;
      }

      this.blocSaleButton = true;

      if (!this.checkSaleValidPrices(this.newSale)) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message: `Hubo un error al guardar la cotización revise que no se tengan artículos con precio final 0`,
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
        this.blocSaleButton = false;
        return;
      }
      if (!this.checkValidDiscounts(this.newSale)) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message: `Hubo un error al guardar la cotización, el total de descuentos de los artículos no puede ser mayor al costo mínimo de venta`,
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
        this.blocSaleButton = false;
        return;
      }
      if (this.newSale.currentItems.length > 0 && this.newSale.CLIENTE) {
        this.$buefy.modal.open({
          canCancel: ["x"],
          parent: this,
          component: AddPaymentMethodsPos,
          props: {
            saleInformation: this.newSale,
            clientInformation: this.clientInformation,
            posId: this.posInformation._id,
          },
          hasModalCard: false,
          customClass: "primary-modal-class",
          trapFocus: true,
          fullScreen: false,
          destroyOnHide: true,
        });
      } else {
        this.$buefy.dialog.confirm({
          title: "Error",
          message: `No hay artículos o cliente seleccionados`,
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }

      this.blocSaleButton = false;
    },
    async saveMissingSalePos() {
      if (!this.newSale.VENDEDOR) {
        this.$buefy.dialog.confirm({
          title: "No hay vendedor seleccionado",
          message: `No se tiene un vendedor seleccionado ¿Esta seguro que desea continua?`,
          type: "is-danger",
          hasIcon: true,
          cancelText: "Cancelar",
          confirmText: "Confirmar",
          onConfirm: async () => {
            this.saveMissingSalePosLogic();
          },
        });
      } else {
        this.saveMissingSalePosLogic();
      }
    },
    async saveMissingSalePosLogic() {
      try {
        if (this.blocSaleButton) {
          return;
        }

        this.blocSaleButton = true;

        if (!this.checkSaleValidPrices(this.newSale)) {
          this.$buefy.dialog.confirm({
            title: "Error",
            message: `Hubo un error al guardar la cotización revise que no se tengan artículos con precio final 0`,
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
          this.blocSaleButton = false;
          return;
        }
        if (!this.checkValidDiscounts(this.newSale)) {
          this.$buefy.dialog.confirm({
            title: "Error",
            message: `Hubo un error al guardar la cotización, el total de descuentos de los artículos no puede ser mayor al costo mínimo de venta`,
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
          this.blocSaleButton = false;
          return;
        }
        if (this.newSale.currentItems.length > 0 && this.newSale.CLIENTE) {
          // Set seller name to current user email if seller was not selected
          if (!this.newSale.NOMBRE_VENDEDOR) {
            this.newSale.NOMBRE_VENDEDOR = this.userInformation.email;
          }

          // Use new date when saving sale
          this.newSale.FECHA = new Date();

          let response = await this.$store.dispatch("SAVEMISSINGSALE", {
            saleInformation: this.newSale,
            posId: this.posInformation._id,
            clientId: this.clientInformation._id,
          });

          if (response.missingSale) {
            this.openPrintTicket(
              {
                ...this.newSale,
                CLAVE_VENTA: response.missingSale.NUMERO_TICKET,
              },
              response.missingSale._id,
              undefined,
              true
            );
            this.currentIndex = 1;
            this.secretUsed = false;
            this.newSale = {
              TIPO: "V",
              OBSERVA: "",
              FECHA: new Date(),
              ALMACEN: "",
              CLIENTE: "",
              total: 0,
              subtotal: 0,
              iva: 0,
              ieps: 0,
              COSTOS: [],
              DESCS: [],
              costs: 0,
              discounts: 0,
              printTimes: 0,
              CONDICION_P: "C",
              METODO_P: "P",
              currentItems: [],
              POS: "S",
              PERI_PAGO: 0,
              CLAVE_USO_SAT: "",
              CLAVE_REGIMEN: "",
              UUID_CFDI: "",
              TIPO_R: "",
              requireInvoice: false,
              requireInvoiceAfterSend: false,
              requireParcialInvoiceAfterSend: false,
              relateCFDI: false,
              VENDEDOR: undefined,
              DESBLOQUEO_CLIENTE: undefined,
              DESBLOQUEO_ALMACEN: undefined,
              sendMaterial: true,
              useAdvancedPayment: false,
              CLAVE_ANTICIPO: undefined,
              CANTIDAD_ANTICIPO: 0,
              RAZON_SOCIAL: undefined,
            };
            this.clientInformation = "";
            this.searchInput = "";
            this.setPosInitialInformation();
            this.setSellerInformation();
            if (this.companyInformation.AUTOCOMPLETAR_CODIGO_ALEATORIO) {
              this.newSale.CODIGO_ENVIO = this.getRandomString(3);
            }
          } else {
            this.$buefy.dialog.confirm({
              title: "Error",
              message: `Hubo un error al guardar la venta`,
              confirmText: "Entendido",
              type: "is-danger",
              hasIcon: true,
              cancelText: "cancelar",
              canCancel: false,
            });
          }
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message: `No hay artículos o cliente seleccionados`,
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }

        this.blocSaleButton = false;
      } catch (error) {
        console.log(error);
        this.$buefy.dialog.confirm({
          title: "Error",
          message: `Hubo un error al guardar la venta en la base de datos`,
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });

        this.blocSaleButton = false;
      }
    },
    async saveQuotePos() {
      if (!this.newSale.VENDEDOR) {
        this.$buefy.dialog.confirm({
          title: "No hay vendedor seleccionado",
          message: `No se tiene un vendedor seleccionado ¿Esta seguro que desea continua?`,
          type: "is-danger",
          hasIcon: true,
          cancelText: "Cancelar",
          confirmText: "Confirmar",
          onConfirm: async () => {
            this.saveQuotePosLogic();
          },
        });
      } else {
        this.saveQuotePosLogic();
      }
    },
    async saveQuotePosLogic() {
      try {
        if (this.blocSaleButton) {
          return;
        }

        this.blocSaleButton = true;

        if (!this.checkSaleValidPrices(this.newSale)) {
          this.$buefy.dialog.confirm({
            title: "Error",
            message: `Hubo un error al guardar la cotización revise que no se tengan artículos con precio final 0`,
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
          this.blocSaleButton = false;
          return;
        }
        if (!this.checkValidDiscounts(this.newSale)) {
          this.$buefy.dialog.confirm({
            title: "Error",
            message: `Hubo un error al guardar la cotización, el total de descuentos de los artículos no puede ser mayor al costo mínimo de venta`,
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
          this.blocSaleButton = false;
          return;
        }

        if (this.newSale.currentItems.length > 0 && this.newSale.CLIENTE) {
          // Set seller name to current user email if seller was not selected
          if (!this.newSale.NOMBRE_VENDEDOR) {
            this.newSale.NOMBRE_VENDEDOR = this.userInformation.email;
          }

          // Use new date when saving sale
          this.newSale.FECHA = new Date();

          let response = await this.$store.dispatch("SAVEQUOTEPOS", {
            saleInformation: this.newSale,
            posId: this.posInformation._id,
            clientId: this.clientInformation._id,
          });

          if (response.posId) {
            this.$buefy.dialog.confirm({
              title: "Éxito",
              message: `Se guardó exitosamente la cotización`,
              confirmText: "Entendido",
              type: "is-success",
              hasIcon: true,
              onConfirm: () => {},
              cancelText: "cancelar",
              canCancel: false,
            });

            this.currentIndex = 1;
            this.secretUsed = false;
            this.newSale = {
              TIPO: "V",
              OBSERVA: "",
              FECHA: new Date(),
              ALMACEN: "",
              CLIENTE: "",
              total: 0,
              subtotal: 0,
              iva: 0,
              ieps: 0,
              COSTOS: [],
              DESCS: [],
              costs: 0,
              discounts: 0,
              printTimes: 0,
              CONDICION_P: "C",
              METODO_P: "P",
              currentItems: [],
              POS: "S",
              PERI_PAGO: 0,
              CLAVE_USO_SAT: "",
              CLAVE_REGIMEN: "",
              UUID_CFDI: "",
              TIPO_R: "",
              requireInvoice: false,
              requireInvoiceAfterSend: false,
              requireParcialInvoiceAfterSend: false,
              relateCFDI: false,
              VENDEDOR: undefined,
              DESBLOQUEO_CLIENTE: undefined,
              DESBLOQUEO_ALMACEN: undefined,
              sendMaterial: true,
              useAdvancedPayment: false,
              CLAVE_ANTICIPO: undefined,
              CANTIDAD_ANTICIPO: 0,
              RAZON_SOCIAL: undefined,
            };
            this.clientInformation = "";
            this.searchInput = "";
            this.setPosInitialInformation();
            this.setSellerInformation();
            if (this.companyInformation.AUTOCOMPLETAR_CODIGO_ALEATORIO) {
              this.newSale.CODIGO_ENVIO = this.getRandomString(3);
            }
          } else {
            this.$buefy.dialog.confirm({
              title: "Error",
              message: `Hubo un error al guardar la cotización`,
              confirmText: "Entendido",
              type: "is-danger",
              hasIcon: true,
              cancelText: "cancelar",
              canCancel: false,
            });
          }
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message: `No hay artículos o cliente seleccionados`,
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }

        this.blocSaleButton = false;
      } catch (error) {
        console.log(error);
        this.$buefy.dialog.confirm({
          title: "Error",
          message: `Hubo un error al guardar la cotización en la base de datos`,
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });

        this.blocSaleButton = false;
      }
    },
    checkSaleValidPrices(sale) {
      for (const singleItem of sale.currentItems) {
        if (singleItem.article && singleItem.finalPrice === 0) {
          return false;
        }
      }
      return true;
    },
    openPrintTicket(saleInformation, saleId, invoiceInformation, presale) {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: PrintTicketModal,
        props: {
          saleInformation: saleInformation,
          saleId: saleId,
          UUID: invoiceInformation ? invoiceInformation.UUID : undefined,
          isPreSale: presale,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    openChangeCalculator() {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: CalculateChangeModal,
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    openAddSecret() {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: SearchSecretModal,
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    checkValidDiscounts(sale) {
      if (this.secretUsed) return true;

      for (const singleItem of sale.currentItems) {
        if (
          singleItem.article &&
          singleItem.article.COSTO_MIN_VTA &&
          singleItem.presentation === "UB" &&
          singleItem.article.defaultListPrices
        ) {
          const orderedPricesList = singleItem.article.defaultListPrices.sort(
            (a, b) => (Number(a.PORC_UTILID) > Number(b.PORC_UTILID) ? 1 : -1)
          );

          const utilityPercentage =
            Number(orderedPricesList[0].PORC_UTILID) / 100;

          if (
            singleItem.price - singleItem.discountTotal + singleItem.costTotal <
            singleItem.article.COSTO_MIN_VTA * (1 + utilityPercentage) - 2
          ) {
            return false;
          }
        }

        if (singleItem.article && singleItem.presentation !== "UB") {
          for (const singlePresentation of singleItem.article.PRESENTACIONES) {
            if (singlePresentation._id === singleItem.presentation) {
              if (singlePresentation.defaultListPrices) {
                const orderedPricesList =
                  singlePresentation.defaultListPrices.sort(
                    (a, b) =>
                      Number(a.calculatedPrice) - Number(b.calculatedPrice)
                  );
                if (
                  singleItem.price -
                    singleItem.discountTotal +
                    singleItem.costTotal <
                  Number(orderedPricesList[0].calculatedPrice) - 1
                ) {
                  return false;
                }
              }
            }
          }
        }

        if (singleItem.article && singleItem.presentation === "UB") {
          if (singleItem.price < singleItem.article.COSTO_MIN_VTA - 1) {
            return false;
          }
        }
      }

      return true;
    },
    getProductTotalQuantity(product) {
      for (const singleItem of this.noEditedArticles) {
        if (product._id === singleItem._id) {
          return singleItem.totalQuantity;
        }
      }
      return 0;
    },
    getProductTotalSalesQuantity(product) {
      for (const singleItem of this.noEditedArticles) {
        if (product._id === singleItem._id) {
          return singleItem.totalQuantitySales;
        }
      }
      return 0;
    },
    getRandomString(length) {
      return createRandomStringCamps(length);
    },
    setSellerInformation() {
      for (const singleUser of this.users) {
        if (singleUser.email === this.userInformation.email) {
          for (const singleEmployee of this.employees) {
            if (singleUser._id === singleEmployee.USER) {
              this.newSale.VENDEDOR = singleEmployee._id;
              this.newSale.NOMBRE_VENDEDOR = `${singleEmployee.NOMBRE} ${singleEmployee.PATERNO} ${singleEmployee.MATERNO}`;
              this.sellerInformation = singleEmployee;
            }
          }
        }
      }
    },
    searchArticles: debounce(async function () {
      try {
        this.current = 1;
        if (!this.searchInput) {
          await this.getArticlesNextPage();
          return;
        }
        await this.$store.dispatch("SEARCHARTICLES", {
          limit: this.perPage,
          skip: this.perPage * (this.current - 1),
          search: this.searchInput,
          saleActive: true,
        });
        if (this.clientInformation) {
          this.setClientPrices(this.clientInformation, false);
        }
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message: `Hubo un error al buscar en la base de datos`,
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    }, 300),
    async getArticlesNextPage() {
      if (!this.searchInput) {
        this.$store.commit("SET_LOADING", true);
        await this.$store.dispatch("GETARTICLESPAGINATED", {
          skip: this.perPage * (this.current - 1),
          limit: this.perPage,
          saleActive: true,
        });
        this.$store.commit("SET_LOADING", false);
        return;
      }

      this.$store.commit("SET_LOADING", true);
      await this.$store.dispatch("SEARCHARTICLES", {
        skip: this.perPage * (this.current - 1),
        limit: this.perPage,
        search: this.searchInput,
        saleActive: true,
      });
      if (this.clientInformation) {
        this.setClientPrices(this.clientInformation, false);
      }
      this.$store.commit("SET_LOADING", false);
    },
  },
  watch: {
    articles() {
      this.calculatePages();
    },
    employees() {
      for (const singleUser of this.users) {
        if (singleUser.email === this.userInformation.email) {
          for (const singleEmployee of this.employees) {
            if (singleUser._id === singleEmployee.USER) {
              this.newSale.VENDEDOR = singleEmployee._id;
              this.newSale.NOMBRE_VENDEDOR = `${singleEmployee.NOMBRE} ${singleEmployee.PATERNO} ${singleEmployee.MATERNO}`;
              this.sellerInformation = singleEmployee;
            }
          }
        }
      }
    },
    pos() {
      this.checkIfCanUsePos();
      this.setPosInitialInformation();

      let allPromises = [];

      allPromises.push(
        this.$store.dispatch("GETARTICLESPAGINATED", {
          skip: this.perPage * (this.current - 1),
          limit: this.perPage,
          saleActive: true,
        })
      );

      Promise.all(allPromises);
    },
  },
  computed: {
    noEditedArticles() {
      return this.$store.getters.ARTICLESPAGINATED.map((singleArticle) => {
        let articleWithProps = { ...singleArticle };

        for (let singleListPresentation of singleArticle.PRESENTACIONES) {
          singleListPresentation.defaultListPrices = [];
        }

        if (this.posInformation) {
          articleWithProps.totalQuantity = 0;
          articleWithProps.totalQuantitySales = 0;

          let defaultListPrices = [];

          //Searching in article price lists
          for (const singlePosPriceList of this.posInformation.LISTA_PRECIOS) {
            let foundPriceList = false;

            for (let singleArticlePriceList of singleArticle.LISTAS_PREC) {
              if (
                singlePosPriceList._id === singleArticlePriceList.CLAVE_LISTAP
              ) {
                foundPriceList = true;
                let getFinalPriceResult = this.getFinalPrice(
                  singleArticle,
                  singleArticlePriceList.PORC_UTILID
                );
                defaultListPrices.push({
                  _id: singleArticlePriceList._id,
                  CLAVE_LISTAP: singleArticlePriceList.CLAVE_LISTAP,
                  NOMBRE_LISTAPT: singleArticlePriceList.NOMBRE_LISTAPT,
                  PORC_UTILID: singleArticlePriceList.PORC_UTILID,
                  calculatedPrice: getFinalPriceResult.calculatedPrice,
                  originalCalculatedPrice: getFinalPriceResult.calculatedPrice,
                  calculatedUtility: getFinalPriceResult.calculatedUtility,
                  forceRendering: 0,
                  removable: false,
                });
              }
            }

            //Searching in article presentation price lists
            for (let singleListPresentation of singleArticle.PRESENTACIONES) {
              let foundPriceListPresentation = false;
              if (singleListPresentation.LISTAS_PREC) {
                for (let singlePriceListPresentation of singleListPresentation.LISTAS_PREC) {
                  if (
                    singlePosPriceList._id ===
                    singlePriceListPresentation.CLAVE_LISTAP
                  ) {
                    foundPriceListPresentation = true;
                    let getFinalPriceResult = this.getFinalPrice(
                      singleArticle,
                      singlePriceListPresentation.PORC_UTILID,
                      false,
                      0,
                      false,
                      singleListPresentation
                    );
                    singleListPresentation.defaultListPrices.push({
                      _id: singlePriceListPresentation._id,
                      CLAVE_LISTAP: singlePriceListPresentation.CLAVE_LISTAP,
                      NOMBRE_LISTAPT:
                        singlePriceListPresentation.NOMBRE_LISTAPT,
                      PORC_UTILID: singlePriceListPresentation.PORC_UTILID,
                      calculatedPrice: getFinalPriceResult.calculatedPrice,
                      originalCalculatedPrice:
                        getFinalPriceResult.calculatedPrice,
                      calculatedUtility: getFinalPriceResult.calculatedUtility,
                      forceRendering: 0,
                      removable: false,
                    });
                  }
                }
              }

              //If article presentation price list was not found
              if (!foundPriceListPresentation) {
                if (singleArticle.COSTO_MIN_VTA) {
                  let formatedPriceList = {
                    ...singlePosPriceList,
                  };
                  formatedPriceList.NOMBRE_LISTAPT =
                    singlePosPriceList.CLAVE_LISTAP;
                  formatedPriceList.CLAVE_LISTAP = singlePosPriceList._id;
                  delete formatedPriceList._id;
                  let getFinalPriceResult = this.getFinalPrice(
                    singleArticle,
                    formatedPriceList.PORC_UTILID,
                    false,
                    0,
                    false,
                    singleListPresentation
                  );
                  formatedPriceList.calculatedPrice =
                    getFinalPriceResult.calculatedPrice;
                  formatedPriceList.originalCalculatedPrice =
                    getFinalPriceResult.calculatedPrice;
                  formatedPriceList.calculatedUtility =
                    getFinalPriceResult.calculatedUtility;
                  formatedPriceList.removable = false;
                  formatedPriceList.forceRendering = 0;
                  singleListPresentation.defaultListPrices.push(
                    formatedPriceList
                  );
                }
              }
            }

            //If article price list was not found
            if (!foundPriceList) {
              if (singleArticle.COSTO_MIN_VTA) {
                let formatedPriceList = {
                  ...singlePosPriceList,
                };
                formatedPriceList.NOMBRE_LISTAPT =
                  singlePosPriceList.CLAVE_LISTAP;
                formatedPriceList.CLAVE_LISTAP = singlePosPriceList._id;
                delete formatedPriceList._id;
                let getFinalPriceResult = this.getFinalPrice(
                  singleArticle,
                  formatedPriceList.PORC_UTILID
                );
                formatedPriceList.calculatedPrice =
                  getFinalPriceResult.calculatedPrice;
                formatedPriceList.originalCalculatedPrice =
                  getFinalPriceResult.calculatedPrice;
                formatedPriceList.calculatedUtility =
                  getFinalPriceResult.calculatedUtility;
                formatedPriceList.forceRendering = 0;
                formatedPriceList.removable = false;
                defaultListPrices.push(formatedPriceList);
              }
            }
          }

          // Get available items
          if (singleArticle.CANT_ALMACENES) {
            for (let singleArticleWarehouse of singleArticle.CANT_ALMACENES) {
              if (
                singleArticleWarehouse.CLAVE_ALMACEN ===
                this.warehouseInformation._id
              ) {
                articleWithProps.totalQuantity += round(
                  Number(singleArticleWarehouse.CANTIDAD, 4)
                );
                articleWithProps.totalQuantitySales += round(
                  Number(singleArticleWarehouse.CANTIDAD, 4)
                );

                if (singleArticleWarehouse.CANTIDAD_VENTAS) {
                  articleWithProps.totalQuantitySales += round(
                    Number(singleArticleWarehouse.CANTIDAD_VENTAS, 4)
                  );
                }
              }
            }
          }

          const notRepeatedDefaulListPrices = [];

          for (const defaultListPrice of defaultListPrices) {
            let found = false;

            for (const notRepeatedDefaulListPrice of notRepeatedDefaulListPrices) {
              if (
                defaultListPrice.CLAVE_LISTAP ===
                notRepeatedDefaulListPrice.CLAVE_LISTAP
              ) {
                found = true;
              }
            }

            if (!found) {
              notRepeatedDefaulListPrices.push(defaultListPrice);
            }
          }

          articleWithProps.defaultListPrices = notRepeatedDefaulListPrices;
        }

        return articleWithProps;
      }).filter((singleArticle) => {
        return (
          singleArticle.ACTIVO_VENTA || singleArticle.ACTIVO_VENTA === undefined
        );
      });
    },
    articles() {
      return this.noEditedArticles.filter((singleItem) => {
        if (singleItem.ACTIVO_VENTA === false) {
          return false;
        }

        return true;
      });
    },
    articlesTotal() {
      return this.$store.getters.ARTICLESPAGINATEDTOTAL;
    },
    currentItems() {
      return this.articles.slice(0, this.perPage);
    },
    userInformation() {
      return this.$store.getters.USER;
    },
    pos() {
      return this.$store.getters.POS;
    },
    companyPaymentPlanModules() {
      if (
        this.$store.getters.COMPANY_INFORMATION &&
        this.$store.getters.COMPANY_INFORMATION.paymentPlan
      ) {
        return this.$store.getters.COMPANY_INFORMATION.paymentPlan.modules;
      }
      return this.$store.getters.COMPANY_INFORMATION.paymentPlan.modules;
    },
    permissions() {
      if (this.$store.getters.USER.permissions) {
        return this.$store.getters.USER.permissions.permissions;
      }
      return this.$store.getters.USER.permissions;
    },
    users() {
      return this.$store.getters.USERS;
    },
    employees() {
      return this.$store.getters.EMPLOYEES.map((singleEmployee) => {
        return singleEmployee;
      }).filter((singleEmployee) => {
        return singleEmployee.TIPO === "VE" || singleEmployee.TIPO === "VC";
      });
    },
    companyInformation() {
      return this.$store.getters.COMPANY_INFORMATION;
    },
  },
};
</script>

<style scoped>
@import "../style/Global.css";
@import "../style/PointOfSale.css";
</style>
